export const NetworkContextName = "Fiers chain";
export const ACTIVE_NETWORK = 1001;
export const RPC_URL = "https://rpc2.fieroscan.com/";

export const characterGenerativeNFTContract =
  process.env.REACT_APP_CHARACTER_GENERATIVE_NFT_CONTRACT;
export const carGenerativeNFTContract =
  process.env.REACT_APP_CAR_GENERATIVE_NFT_CONTRACT;
export const bikeGenerativeNFTContract =
  process.env.REACT_APP_BIKE_GENERATIVE_NFT_CONTRACT;
export const hoverBoardGenerativeNFTContract =
  process.env.REACT_APP_HOVER_GENERATIVE_NFT_CONTRACT;
export const promotionalGameContract =
  process.env.REACT_APP_PROMOTIONAL_GAME_CONTRACT;

export const currencyUnit = "Fiero";

export const NetworkDetails = [
  {
    chainId: "0x3E9",
    chainName: "Fiers Mainnet",
    nativeCurrency: {
      name: "FIERO",
      symbol: "FIERO",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://fieroscan.com"],
  },
];
