export const baseurl = process.env.REACT_APP_BASE_URL;
export const socketBaseurl = process.env.REACT_APP_BASE_URL;
export const webSocketUrl = process.env.REACT_APP_WEB_SOCKET_URL;
export const unitySandCardUrl = process.env.REACT_APP_WEB_UNITY_URL;

//////local/////
// export const baseurl = "http://172.16.1.254:1978"; //local URL
// export const socketBaseurl = "http://172.16.1.254:1978"; //local URL
// export const webSocketUrl = "ws://172.16.1.254:2100"; //local URL
// export const unitySandCardUrl = "http://172.16.11.253:2004"; //local URL

let url = `${baseurl}/api/v1`;

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  getProfileUser: `${url}/user/getProfile`,
  editProfile: `${url}/user/editProfile`,
  participatedEvents: `${url}/user/participatedEvents`,

  // //// //// admin-----------
  login: `${url}/admin/login`,
  forgotPassword: `${url}/admin/forgotPassword`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resetPasswordAdmin: `${url}/admin/resetPasswordAdmin`,
  userList: `${url}/admin/userList`,
  userBlockUnblock: `${url}/admin/blockUnblockUser`,
  transactionManagement: `${url}/admin/mintingTransactionList`,
  changePassword: `${url}/admin/changePassword`,
  dashboard: `${url}/admin/dashboard`,
  profile: `${url}/admin/profile`,
  deleteUser: `${url}/admin/deleteUser`,
  updateUserProfile: `${url}/admin/updateUserProfile`,

  ///sub admin
  subAdminList: `${url}/admin/subAdminList`,
  createSubAdmin: `${url}/admin/createSubAdmin`,
  updateSubAdmin: `${url}/admin/updateSubAdmin`,
  blockUnblockSubAdmin: `${url}/admin/blockUnblockSubAdmin`,
  deleteSubAdmin: `${url}/admin/deleteSubAdmin`,
  listAllSubAdmin: `${url}/admin/listAllSubAdmin`,

  ///static content
  staticContent: `${url}/static/staticContent`,
  staticContentList: `${url}/static/staticContentList`,

  // event
  eventSchedule: `${url}/event/eventSchedule`,
  listEvent: `${url}/event/listEvent`,
  event: `${url}/event/event`,
  events: `${url}/event/event/`,
  joinEvent: `${url}/event/joinEvent`,
  enterEvent: `${url}/event/enterEvent`,
  statistics: `${url}/event/statistics`,
  claimAmount: `${url}/event/claimAmount`,
  refundUser: `${url}/event/refundUser`,

  // user event -----
  upcomingEvents: `${url}/user/upcomingEvents`,
  pastEvents: `${url}/user/pastEvents`,
  listCircuit: `${url}/circuit/listCircuit`,

  ///nft///
  createNFT: `${url}/nft/createNFT`,
  nftList: `${url}/nft/nftList`,
  mintedList: `${url}/nft/mintedList`,

  ///race//
  getRaceStatus: `${url}/circuit/getRaceStatus`,

  ///garage//
  listGarage: `${url}/garage/listGarage`,
  listItems: `${url}/garage/listItems`,
  listPurchasedGarages: `${url}/garage/listPurchasedGarages`,
  addGarage: `${url}/garage/addGarage`,
  blockUnBlockGarage: `${url}/garage/blockUnBlockGarage`,
  deleteGarage: `${url}/garage/deleteGarage`,
  specifications: `${url}/garage/specifications`,

  purchaseGarageListHistory: `${url}/user/purchaseGarageListHistory`,
  redeemGarage: `${url}/user/redeemGarage`,
  buyGarage: `${url}/user/buyGarage`,

  //circuit
  getLevelList: `${url}/circuit/getLevelList`,
  updateLevelList: `${url}/circuit/updateLevelList`,

  //announcement
  createAnouncement: `${url}/announcement/createAnouncement`,
  updateAnouncement: `${url}/announcement/updateAnouncement`,
  listAnouncemnt: `${url}/announcement/listAnouncemnt`,
  deleteAnouncement: `${url}/announcement/deleteAnouncement`,

  //advertisement
  createAdvertisement: `${url}/advertisement/createAdvertisement`,
  updateAdvertisement: `${url}/advertisement/updateAdvertisement`,
  listAdvertisement: `${url}/advertisement/listAdvertisement`,
  deleteAdvertisement: `${url}/advertisement/deleteAdvertisement`,

  //IpManagement
  createIP: `${url}/ipManagement/createIP`,
  updateIP: `${url}/ipManagement/updateIP`,
  deleteIP: `${url}/ipManagement/deleteIP`,
  blockUnblockIP: `${url}/ipManagement/blockUnblockIP`,
  listIP: `${url}/ipManagement/listIP`,
  enableDisableIP: `${url}/ipManagement/enableDisableIP`,

  //auto event
  autoEventConfiguraton: `${url}/event/autoEventConfiguraton`,
  updateAutoEventConfiguraton: `${url}/event/updateAutoEventConfiguraton`,
  enableDisableAutoEvent: `${url}/event/enableDisableAutoEvent`,

  ////spin game
  checkFreeSpin: `${url}/user/checkFreeSpin`,
  buySpin: `${url}/user/buySpin`,
  spinGame: `${url}/user/spinGame`,
  claimNft: `${url}/user/claimNft`,

  ///// ////spin game
  spinTransactionList: `${url}/admin/spinTransactionList`,
  updateSpinPrice: `${url}/admin/updateSpinPrice`,
  getSpinPrice: `${url}/admin/getSpinPrice`,
};
export default ApiConfig;
