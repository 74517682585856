import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Dialog, DialogContent, Slide } from "@material-ui/core";
import Footer from "./Footer";
import TopBar from "./TopBar";
import { AuthContext } from "src/context/Auth";
import SpinModal from "src/views/pages/SpinGame/SpinModal";
import SpinResultModal from "src/views/pages/SpinGame/SpinResultModal";
import { apiRouterCall } from "src/ApiConfig/service";
import Lottie from "lottie-react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    "& .leftShade2": {
      position: "relative",
      zIndex: "999",
      boxShadow: "39px -9px 60px 49px #000000",
    },
    "& .lottieSec": {
      "& svg": {
        width: "56px !important",
      },
    },
  },
  MainLayout: {
    minHeight: "calc(100vh - 382px)",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [spinResultModalOpen, setSpinResultModalOpen] = useState(false);
  const [finalValue, setFinalValue] = useState("");
  const [spinStatus, setSpinStatus] = useState();
  const [spinId, setSpinId] = useState("");
  const [animationData, setAnimationData] = useState(null);

  const getCheckSpinGame = async () => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "checkFreeSpin",
      });
      if (response.data.responseCode === 200) {
        setSpinStatus(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch("/json/fortuneWheel.json") // public folder ke andar ka JSON
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) => console.error("Error loading JSON:", error));
  }, []);

  useEffect(() => {
    if (auth?.account) {
      getCheckSpinGame();
    }
  }, [auth?.account]);

  useEffect(() => {
    window.localStorage.removeItem("admin_token");
    auth.setAdminIsLogin(false);
  }, [auth]);

  useEffect(() => {
    if (!localStorage.getItem("showSpin") && spinStatus?.type === "FREE") {
      auth.setSpinModalOpen(true);
      localStorage.setItem("showSpin", true);
    }
  }, [localStorage.getItem("showSpin"), spinStatus]);

  return (
    <div className={classes.root}>
      <div className="leftShade2"></div>
      {/* <Box className="loginRightSahdowdash"></Box> */}
      <TopBar />
      <div
        style={
          window.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />

      <div
        className="fixed bottom-[30%] right-0 z-[999] cursor-pointer  lg:w-14 lg:h-14 rouned-full lottieSec"
        style={{ filter: "drop-shadow(0px 2px 22px orange)" }}
        onClick={() => auth.setSpinModalOpen(true)}
      >
        {/* <img
          src="/images/games/spinIcon.png"
          alt="spin-icon"
          className="w-full animate-spin"
        /> */}

        <Lottie animationData={animationData} loop={true} />
      </div>

      {auth.spinModalOpen && (
        <Dialog
          open={auth.spinModalOpen}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          onClose={() => {
            auth.setSpinModalOpen(false);
            setFinalValue("");
          }}
          maxWidth="sm"
          fullWidth
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ padding: "0px" }}>
            <SpinModal
              setSpinResultModalOpen={setSpinResultModalOpen}
              setSpinModalOpen={auth.setSpinModalOpen}
              setFinalValue={setFinalValue}
              spinStatus={spinStatus}
              getCheckSpinGame={getCheckSpinGame}
              finalValue={finalValue}
              setSpinId={setSpinId}
            />
          </DialogContent>
        </Dialog>
      )}
      {spinResultModalOpen && (
        <Dialog
          open={spinResultModalOpen}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          maxWidth="sm"
          fullWidth
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ padding: "0px" }}>
            <SpinResultModal
              setSpinResultModalOpen={setSpinResultModalOpen}
              finalValue={finalValue}
              setSpinModalOpen={auth.setSpinModalOpen}
              setFinalValue={setFinalValue}
              spinId={spinId}
              setSpinId={setSpinId}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default MainLayout;
