import React, { useState, useRef, useEffect, useContext } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { gameSound } from "src/App";
import { capitalizeFirstLetter } from "src/utils";
import { AuthContext } from "src/context/Auth";
import toast from "react-hot-toast";
import { apiRouterCall } from "src/ApiConfig/service";
import Web3 from "web3";

export const getWeb3Obj = () => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum); // Use MetaMask's injected provider
    return web3;
  } else {
    throw new Error(
      "MetaMask is not installed. Please install MetaMask to interact with the blockchain."
    );
  }
};

const SpinModal = ({
  setFinalValue,
  setSpinModalOpen,
  setSpinResultModalOpen,
  spinStatus,
  getCheckSpinGame,
  finalValue,
  setSpinId,
}) => {
  const auth = useContext(AuthContext);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isOn, setIson] = useState(false);
  const [buttonText, setButtonText] = useState("Spin Now");
  const isMountedRef = useRef(true);
  const wheelRef = useRef(null);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;

  const rotationValues = [
    { minDegree: 0, maxDegree: 20, value: "AVATAR" },
    { minDegree: 20, maxDegree: 40, value: "Better Luck!" },
    { minDegree: 40, maxDegree: 60, value: "HOVERBOARD" },
    { minDegree: 60, maxDegree: 80, value: "CAR" },
    { minDegree: 80, maxDegree: 100, value: "Better Luck!" },
    { minDegree: 100, maxDegree: 120, value: "AVATAR" },
    { minDegree: 120, maxDegree: 140, value: "CAR" },
    { minDegree: 140, maxDegree: 160, value: "Better Luck!" },
    { minDegree: 160, maxDegree: 180, value: "HOVERBOARD" },
    { minDegree: 180, maxDegree: 200, value: "BIKE" },
    { minDegree: 200, maxDegree: 220, value: "Better Luck!" },
    { minDegree: 220, maxDegree: 240, value: "BIKE" },
    { minDegree: 240, maxDegree: 260, value: "Better Luck!" },
    { minDegree: 260, maxDegree: 280, value: "CAR" },
    { minDegree: 280, maxDegree: 300, value: "AVATAR" },
    { minDegree: 300, maxDegree: 320, value: "BIKE" },
    { minDegree: 320, maxDegree: 340, value: "Better Luck!" },
    { minDegree: 340, maxDegree: 360, value: "CAR" },
  ];

  const handleCheckSpinAval = async () => {
    try {
      setBtnDisabled(true);
      setButtonText("Loading...");
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "spinGame",
        bodyData: {
          spinType: spinStatus?.type,
        },
      });
      if (response?.data?.responseCode === 200) {
        return response;
      } else {
        getCheckSpinGame();
        setBtnDisabled(false);
        setButtonText("Spin Now");
        toast.error(response.data.message);
        return false;
      }
    } catch (error) {
      getCheckSpinGame();
      setBtnDisabled(false);
      setButtonText("Spin Now");
      console.log(error);
      toast.error(error.message);
      return false;
    }
  };

  const handlePay = async () => {
    try {
      setBtnDisabled(true);
      setButtonText("Paying...");
      if (Number(spinStatus?.price || 0) < Number(auth.yourWalletBalance)) {
        const web3 = getWeb3Obj();
        const receipt = await web3.eth.sendTransaction({
          from: auth.account,
          to: auth?.userData?.adminWalletAddress,
          value: web3.utils.toWei(spinStatus?.price?.toString()),
        });
        await apiRouterCall({
          method: "POST",
          endPoint: "buySpin",
          bodyData: {
            transactionHash: receipt.transactionHash,
            amount: spinStatus?.price,
          },
        });
        handleSpin();
      } else {
        setBtnDisabled(false);
        setButtonText("Spin Now");
        toast.error("Your balance is too low. Please add more FIERO.");
      }
    } catch (error) {
      setBtnDisabled(false);
      setButtonText("Spin Now");
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleSpin = async () => {
    try {
      setBtnDisabled(true);
      setButtonText("Processing...");
      const response = await handleCheckSpinAval();
      const spinWheel = document.querySelector("#spin-wheel");
      spinWheel.style.transition = "none";
      spinWheel.style.transform = `translateY(-11px) rotate(0deg)`;

      const backendDegree = response?.data?.data?.startDegree;
      const rotationDuration = 3000;
      const totalSpins = 18;
      const finalDegree = totalSpins * 360 + (360 - backendDegree);

      const segment = rotationValues.find(
        (it) => backendDegree >= it.minDegree && backendDegree < it.maxDegree
      );

      spinWheel.style.transition = `transform ${rotationDuration}ms ease-out`;
      spinWheel.style.transform = `translateY(-11px) rotate(${finalDegree}deg)`;

      gameSound.spinStart.play();

      setTimeout(() => {
        gameSound.spinStart.pause();
        gameSound.spinEnd.play();
        const valueAtDegree = segment?.value;
        valueAtDegree !== "Better Luck!" &&
          auth.setSelectCategory(valueAtDegree);
        setFinalValue(valueAtDegree);
        setSpinId(response?.data?.data?.spinId);
        setBtnDisabled(false);
        setButtonText("Spin Now");
      }, rotationDuration);

      setTimeout(() => {
        gameSound.spinStart.pause();
        gameSound.spinEnd.pause();
        if (segment?.value !== "Better Luck!") {
          gameSound.applause.play();
        }
        setSpinResultModalOpen(true);
        getCheckSpinGame();
        const spinWheel = document.querySelector("#spin-wheel");
        spinWheel.style.transition = "none";
        spinWheel.style.transform = `translateY(-11px) rotate(0deg)`;
      }, 5000);
    } catch (error) {
      console.error("Error fetching spin degree:", error);
      setBtnDisabled(false);
      setButtonText("Spin Now");
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    const circles = document.querySelectorAll(".light-circle");
    const interval = setInterval(() => {
      circles.forEach((el, i) => {
        setTimeout(
          () => {
            if (isMountedRef.current) {
              el.style.background = isOn ? "white" : "rgba(249, 237, 50, 1)";
              el.style.boxShadow = isOn
                ? "0 0 15px 0 " +
                  (i % 2 === 0 ? "rgba(249, 237, 50, 1)" : "white")
                : "0 0 5px 0 lightgray";
            }
          },
          i % 2 ? 300 : 100
        );
      });
      setIson((prev) => !prev);
    }, 600);

    return () => {
      clearInterval(interval);
      isMountedRef.current = false;
    };
  }, [isOn]);

  return (
    <div
      className="spin-modal"
      style={{
        backgroundImage: `url(${"/images/spinBannerBonus.png"})`,
      }}
    >
      <div
        className={`spinCloseButton ${
          btnDisabled ? "pointer-events-none" : "pointer-events-auto"
        }`}
        onClick={() => {
          if (!btnDisabled) {
            setSpinModalOpen(false);
            setFinalValue("");
            gameSound.spinStart.pause();
            gameSound.spinEnd.pause();
          }
        }}
      >
        <img
          src="/images/close_spinner.svg"
          alt="closeIcon"
          width="31px"
          height="31px"
        />
      </div>

      <img
        src="/images/spinnerText.png"
        alt="Spinner"
        className="text-design"
      />

      <div className="wheel-container wheel-containertopmain relative rounded-full">
        {Array.from({ length: 12 }, (_, i) => (
          <div className={`light-circle light-circle-${i + 1}`} key={i}></div>
        ))}
        <img
          src="/images/games/spinWheel.png"
          alt="spinWheel"
          id="spin-wheel"
          ref={wheelRef}
        />
        <img
          src="/images/games/spinner_1.png"
          alt="spinner"
          className="w-12 h-12 object-contain absolute top-0 z-[9]"
        />
        <img
          src="/images/games/spinCenter.png"
          alt="spinArrow"
          className="spin-center w-28 h-28 object-contain absolute top-[48%] left-[50%] -translate-y-1/2 -translate-x-1/2 z-[9]"
        />
        <img
          src="/images/games/spinRing.webp"
          alt="spinRing"
          className="spin-ring"
        />
      </div>

      <div style={{ textAlign: "center" }}>
        <img
          src="/images/games/spinner_bottom.png"
          alt="Banner"
          className="gamecenter-Img"
        />
      </div>

      <div style={{ textAlign: "center" }} className="mainTopmodalSection">
        {auth.account && (
          <span className="almuniText">
            {spinStatus?.type === "FREE"
              ? "Free Spin Available"
              : `Pay ${spinStatus?.price || 0} Fiero For Spin`}
          </span>
        )}
        <div
          onClick={() => {
            if (!btnDisabled && !finalValue) {
              !auth.account
                ? auth.setOpenMetamask(true)
                : spinStatus?.type === "FREE"
                ? handleSpin()
                : handlePay();
            }
          }}
          className="displayCenter pointer-events-auto cursor-pointer"
        >
          <button className="spin-cutumButton displayCenter">
            <span className="button-text">
              {!auth.account
                ? "Connect Wallet"
                : finalValue
                ? finalValue !== "Better Luck!"
                  ? `Claim ${capitalizeFirstLetter(finalValue)} Nft`
                  : finalValue
                : buttonText}
            </span>
          </button>
        </div>
        <Typography
          variant="body2"
          color="primary"
          className="almuniText"
          style={{ padding: "10px" }}
        >
          Hit 'Spin Now' for a chance to grab free NFTs!
        </Typography>
      </div>
    </div>
  );
};

export default SpinModal;
