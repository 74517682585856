import { Contract } from "@ethersproject/contracts";
import Web3 from "web3";
import { ACTIVE_NETWORK, NetworkDetails, RPC_URL } from "src/constants";
import moment from "moment";
import { getAPIHandler } from "src/ApiConfig/service";
import * as XLSX from "xlsx";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}
export function calculatePercentage(part, whole) {
  return (part / whole) * 100;
}
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const swichNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      addNetworkHandler();
    }
  }
};
const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
export const getNumberInputProps = () => ({
  onKeyPress: (event) => {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault();
    }
  },
  onKeyDown: (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
    }
  },
  InputProps: {
    inputProps: {
      step: 1,
      min: 0,
    },
    endAdornment: null,
  },
});

export const calculateTimeLeftForOtp = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const calculateTimeLeft = (endDate) => {
  if (!endDate) return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  const difference = +new Date(endDate) - +new Date();
  if (difference <= 0) return "live";
  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
      2,
      "0"
    ),
    minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, "0"),
    seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
  };
};

export const calculateTimeLeftForRace = (endDate) => {
  if (endDate) {
    // let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (endDate > 0) {
      timeLeft = {
        days: Math.floor(endDate / (1000 * 60 * 60 * 24)),
        hours: Math.floor((endDate / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((endDate / 1000 / 60) % 60),
        seconds: Math.floor((endDate / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export function convertToMinutesAndSeconds(seconds) {
  return `${Math.floor(seconds / 60)}m :${Math.floor(seconds % 60)}s`;
}

export function convertUnityToMinutesAndSeconds(seconds) {
  return `${Math.floor(seconds / 60)}m:${Math.floor(seconds % 60)}s`;
}

// export const findEventStatus = (startDate, endDate) => {
//   if (startDate && endDate) {
//     if (moment(endDate).unix() < moment().unix()) {
//       return "Ended";
//     } else if (
//       moment().unix() < moment(endDate).unix() &&
//       moment().unix() > moment(startDate).unix()
//     ) {
//       return "Live";
//     } else {
//       return "Upcoming";
//     }
//   }
// };

export const findEventStatus = (startDate, endDate) => {
  if (startDate && endDate) {
    const now = moment().unix();
    const start = moment(startDate).unix();
    const end = moment(endDate).unix();

    if (now > end) {
      return "Ended";
    } else if (now >= start && now <= end) {
      return "Live";
    } else {
      return "Upcoming";
    }
  }
  return "Invalid Date Range"; // Optional fallback for missing/invalid dates
};

export function numberCompactFormat(value) {
  if (value < 1) {
    return value.toFixed(4);
  }
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
}

export const paramsVal = {
  secure: true,
  reconnect: true,
  rejectUnauthorized: false,
  transports: ["websocket", "polling", "flashsocket"],
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatNumber = (num, lenVal) => {
  return Number.isInteger(num)
    ? num.toLocaleString()
    : +parseFloat(num).toFixed(lenVal).toLocaleString();
};

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};

export const downloadExcel = (dataToPrint, sheetName) => {
  const now = new Date();
  const currentTime = `${now.toLocaleDateString(
    "en-GB"
  )} ${now.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;

  const cleanedData = dataToPrint.map((item) =>
    Object.fromEntries(
      Object.entries(item).map(([key, value]) => [
        key,
        typeof value === "string" && value.length > 32767
          ? value.substring(0, 32767)
          : value,
      ])
    )
  );

  if (cleanedData.length === 0) return console.error("No data to export.");

  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(cleanedData);
  const formattedSheetName = `${sheetName}${currentTime}`
    .slice(0, 31)
    .replace(/[\/:*?"<>|]/g, "_");

  XLSX.utils.book_append_sheet(workBook, workSheet, formattedSheetName);
  XLSX.writeFile(workBook, `${formattedSheetName}.xlsx`);
};

export const listUserHandlerExcel = async ({ paramsData, endPoint }) => {
  try {
    const res = await getAPIHandler({
      endPoint: endPoint,
      paramsData: {
        page: 1,
        ...paramsData,
      },
      token:
        localStorage.getItem("admin_token") || localStorage.getItem("token"),
    });
    if (res.data.responseCode === 200) {
      return res.data.data.docs;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const isValidIPv6 = (ip) => {
  const ipv6Pattern =
    /^(?:\d{1,3}\.){3}\d{1,3}|(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,7}:|(?:[0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|(?:[0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|(?:[0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|(?:[0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}(:[0-9a-fA-F]{1,4}){1,6}|:(:[0-9a-fA-F]{1,4}){1,7}|::(?:[0-9a-fA-F]{1,4}){1,7}$/;
  return ipv6Pattern.test(ip);
};

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

let sortOrder = "asc";
export const sortArrayData = (initialValue, key) => {
  const sortedArray = [...initialValue].sort((a, b) => {
    const valueA = isNaN(a[key])
      ? String(a[key]).charAt(0).toUpperCase()
      : parseFloat(a[key]);
    const valueB = isNaN(b[key])
      ? String(b[key]).charAt(0).toUpperCase()
      : parseFloat(b[key]);
    const compareResult =
      typeof valueA === "string"
        ? valueA.localeCompare(valueB)
        : valueA - valueB;
    return sortOrder === "asc" ? compareResult : -compareResult;
  });
  sortOrder = sortOrder === "asc" ? "desc" : "asc";
  return sortedArray;
};

export function toSpaceSeparated(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export const clearFieldsBasedOnSelection = (values) => {
  const { allAssets, ...rest } = values;
  return {
    ...rest,
    allAssets: allAssets,
    nitro: allAssets === "nitro" ? rest.nitro : undefined,
    speed: allAssets === "speed" ? rest.speed : "",
    motorTorque: allAssets === "motorTorque" ? rest.motorTorque : "",
    brakeTorque: allAssets === "brakeTorque" ? rest.brakeTorque : "",
    mass: allAssets === "mass" ? rest.mass : "",
  };
};

export const routeGroups = {
  subAdminRoutes: [
    "/sub-admin-management",
    "/add-sub-admin",
    "/view-sub-admin",
  ],
  EventRoutes: [
    "/event-managemet",
    "/add-event",
    "/view-event", // Fixed route name here
  ],
  GarageRoutes: [
    "/garage-managemet",
    "/add-garage-newitem",
    "/view-garage", // Fixed route name here
  ],
  AnnouncementRoutes: [
    "/announcement-management",
    "/edit-announcement",
    "/add-announcement",
  ],
  AdvertisementRoutes: [
    "/advertisement-management",
    "/edit-advertisement",
    "/add-advertisement",
  ],
  UserRoutes: ["/usermanagemant", "/user-profile"],
  staticRoutes: ["/static", "/view-content", "/edit-content"],
};

export const getUpdatedRoutes = (matchedArray, routeGroups) => {
  const existingRoutes = new Set(matchedArray);
  for (const routes of Object.values(routeGroups)) {
    const isGroupMatched = routes.some((route) => matchedArray.includes(route));
    if (isGroupMatched) {
      routes.forEach((route) => {
        existingRoutes.add(route);
      });
    }
  }
  return Array.from(existingRoutes);
};

export const showAssetValue = (data) => {
  return data?.motorTorque || data?.mass || data?.topSpeed || data?.brakeTorque;
};
export function checkImage(type) {
  return type == "AVATAR"
    ? "images/avatarImg.png"
    : type == "CAR"
    ? "images/carImg.png"
    : type == "HOVERBOARD"
    ? "images/hoverBoardImg.png"
    : "images/bikeImg.png";
}
