import bgSound from "src/views/pages/SpinGame/sounds/bgMusic.mp3";
import diceRoll from "src/views/pages/SpinGame/sounds/Diceroll.mp3";
import cuttingSound from "src/views/pages/SpinGame/sounds/cuttingSound.mp3";
import cuttingBack from "src/views/pages/SpinGame/sounds/cuttingBack.mp3";
import pawnMove from "src/views/pages/SpinGame/sounds/Jump.mp3";
import win from "src/views/pages/SpinGame/sounds/win.mp3";
import loose from "src/views/pages/SpinGame/sounds/loose.mp3";
import spinStart from "src/views/pages/SpinGame/sounds/spinStart.mp3";
import spinEnd from "src/views/pages/SpinGame/sounds/spinSlow.mp3";
import winClap from "src/views/pages/SpinGame/sounds/winClap.mp3";
import applause from "src/views/pages/SpinGame/sounds/applause.mp3";
import claimReward from "src/views/pages/SpinGame/sounds/claimReward.wav";
import gameStartSound from "src/views/pages/SpinGame/sounds/gameStartSound.mp3";
import { Howl, Howler } from "howler";

export class GameSound {
  constructor() {
    this.bgAudio = new Howl({
      src: bgSound,
      loop: true,
      volume: 0.5,
      preload: true,
      autoplay: false,
    });
    this.gameAudio = new Howl({
      src: [diceRoll],
    });
    this.cutAudio = new Howl({
      src: [cuttingSound],
    });
    this.swipAudio = new Howl({
      src: [cuttingBack],
    });
    this.pawnMove = new Howl({
      src: [pawnMove],
    });
    this.winSound = new Howl({
      src: [win],
    });
    this.looseSound = new Howl({
      src: [loose],
    });
    this.gameStartSound = new Howl({
      src: [gameStartSound],
    });

    this.spinStart = new Howl({
      src: [spinStart],
    });
    this.spinEnd = new Howl({
      src: [spinEnd],
    });
    this.winClap = new Howl({
      src: [winClap],
    });
    this.applause = new Howl({
      src: [applause],
    });
    this.claimReward = new Howl({
      src: [claimReward],
    });
    this.looseSound = new Howl({
      src: [loose],
    });
    this.gameStartSound = new Howl({
      src: [gameStartSound],
    });
  }

  setVolume(type, value = 0.5) {
    switch (type) {
      case "music": {
        // this.audio.volume = value;
        break;
      }
      case "dice": {
        this.gameAudio.volume = value;
        break;
      }
      default: {
        console.error("invalid type");
      }
    }
  }

  updateVolume(val) {
    this.gameAudio.volume(val);
    this.cutAudio.volume(val);
    this.swipAudio.volume(val);
    this.pawnMove.volume(val);
  }

  updateBgVolume(val) {
    this.bgAudio.volume(val);
  }

  playdiceSound() {
    this.gameAudio.play();
  }

  playCuttingSound() {
    this.cutAudio.play();
  }
  playSwipSound() {
    this.swipAudio.play();
  }

  playPawnMove() {
    this.pawnMove.play();
  }
  pausePawnMove() {
    this.pawnMove.pause();
  }
  playBgAudio() {
    this.bgAudio.play();
  }
  pauseBgAudio() {
    this.bgAudio.pause();
  }
  playWinSound() {
    this.winSound.play();
  }
  pauseWinSound() {
    this.winSound.pause();
  }

  playLooseSound() {
    this.looseSound.play();
  }
  pauseLooseSound() {
    this.looseSound.pause();
  }

  playGameStartSound() {
    console.log("playgin.....");
    this.gameStartSound.play();
  }

  pauseGameStartSound() {
    this.gameStartSound.pause();
  }
}
