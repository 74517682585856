import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { apiRouterCall } from "src/ApiConfig/service";
import { AuthContext } from "src/context/Auth";
import { capitalizeFirstLetter, checkImage } from "src/utils";
import { Typography } from "@material-ui/core";

function SpinResultModal({
  setSpinResultModalOpen,
  finalValue,
  setFinalValue,
  spinId,
  setSpinId,
}) {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const claimNftReward = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "claimNft",
        bodyData: { _id: spinId },
      });
      if (response.data.responseCode === 200) {
        toast.success(response.data.message);
        setFinalValue("");
        setSpinId("");
        setSpinResultModalOpen(false);
      } else {
        toast.error(response.data.message);
        setErrorMsg(
          "If you're facing any issue with claiming it, you can do it from your profile."
        );
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setFinalValue("");
    setSpinId("");
    setSpinResultModalOpen(false);
  };

  return (
    <div className="spin-result-modal p-4 flex justify-center items-center overflow-hidden">
      {errorMsg && (
        <div
          className={`spinCloseButton ${
            isLoading ? "pointer-events-none" : "pointer-events-auto"
          }`}
          onClick={() => {
            if (!isLoading) {
              handleCancel();
            }
          }}
        >
          <img
            src="/images/close_spinner.svg"
            alt="closeIcon"
            width="31px"
            height="31px"
          />
        </div>
      )}
      <div>
        <img
          src={
            finalValue && finalValue !== "Better Luck!"
              ? "/images/games/Congratulations.png"
              : "/images/games/OppsImg.png"
          }
          alt="Spinner"
          className="text-design"
        />
      </div>

      <Typography variant="h3">
        {finalValue && finalValue !== "Better Luck!"
          ? `You Won A ${capitalizeFirstLetter(finalValue)}`
          : "Better Luck Next Time!"}
      </Typography>

      <div>
        <img
          style={{
            height: "232px",
            borderRadius: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          src={
            finalValue && finalValue !== "Better Luck!"
              ? checkImage(finalValue)
              : "/images/games/BetterLuckNextTime.png"
          }
        />
      </div>
      {finalValue && finalValue !== "Better Luck!" && (
        <div style={{ marginTop: "10px" }}>
          <span className="originalFierovalue">
            Original Cost - {auth?.perNftPrice || 0} Fiero
          </span>
        </div>
      )}
      {finalValue && finalValue !== "Better Luck!" && errorMsg && (
        <span
          className="almuniText"
          style={{ fontSize: "12px", color: "#ffb1b1", fontWeight: 700 }}
        >
          {errorMsg}
        </span>
      )}
      <button
        className="spin-cutumButton displayCenter"
        onClick={() => {
          if (!isLoading) {
            if (finalValue && finalValue !== "Better Luck!") {
              claimNftReward();
            } else {
              handleCancel();
            }
          }
        }}
      >
        <span className="button-text">
          {isLoading
            ? "Loading..."
            : finalValue && finalValue !== "Better Luck!"
            ? "Claim Now"
            : "Spin Again"}
        </span>
      </button>
    </div>
  );
}

export default SpinResultModal;
