import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import {
  ACTIVE_NETWORK,
  carGenerativeNFTContract,
  characterGenerativeNFTContract,
  hoverBoardGenerativeNFTContract,
  bikeGenerativeNFTContract,
  promotionalGameContract,
} from "src/constants";
import { injected } from "src/connectors";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import {
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from "src/utils";
import BothgenerativeNFTABI from "src/ABI/BothgenerativeNFTABI.json";
import CharacterNftAbi from "src/ABI/CharacterNftAbi.json";
import { apiRouterCall, getAPIHandler } from "src/ApiConfig/service";
import web3 from "web3";
import ApiConfig from "src/ApiConfig/ApiConfig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}
function checkAdminLogin() {
  const accessToken = window.localStorage.getItem("admin_token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [adminIsLogin, setAdminIsLogin] = useState(checkAdminLogin());
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const [mintDataLoad, setMintDataLoad] = useState(false);
  const [openMetamask, setOpenMetamask] = useState(false);
  const [isibiRequire, setisibiRequire] = useState(false);
  const [spinModalOpen, setSpinModalOpen] = useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [maxNftSupply, setMaxNftSupply] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [perNftPrice, setPerNftPrice] = useState("");
  const [selectCategory, setSelectCategory] = useState("AVATAR");
  const [userData, setuserData] = useState({});
  const [adminUserData, setAdminUserData] = useState({});

  const walletConnect = async (referral) => {
    setMetaMaskLoading(true);
    try {
      await activate(injected, undefined, true);
      window.localStorage.setItem("ConnectWallet", true);
      setOpenMetamask(false);
    } catch (error) {
      console.error("ERROR", error);
      toast.error(error.message);
      setOpenMetamask(false);
      activate(injected);
    }
    setMetaMaskLoading(false);
  };

  const connnectWalletApi = async (account) => {
    try {
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "connectWallet",
        bodyData: {
          walletAddress: account,
        },
      });
      if (response?.data?.responseCode === 200) {
        !localStorage.getItem("token") && toast.success(response.data.message);
        window.localStorage.setItem("token", response?.data?.data?.token);
        setSession(response?.data?.data?.token);
        setIsLogin(true);
        GetProfileUser(response?.data?.data?.token);
      } else {
        toast.error(response?.data?.message);
        disconnectWalletHandler(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetProfileUser = async (token) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getProfileUser",
        headers: {
          token: token,
        },
      });
      if (response?.data?.responseCode === 200) {
        setisibiRequire(!response?.data?.data?.isibiRequire);
        setuserData(response?.data?.data);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const adminProfileApi = async (token) => {
    try {
      const response = await axios({
        method: "GET",
        url: ApiConfig.profile,
        headers: {
          token: token,
        },
      });
      if (response?.data?.responseCode === 200) {
        setAdminUserData(response?.data?.data);
        setAdminIsLogin(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOurWalletBlc = async () => {
    const web3 = getWeb3Obj();
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  const disconnectWalletHandler = async (condition) => {
    try {
      deactivate();
      window.localStorage.removeItem("ConnectWallet");
      window.localStorage.removeItem("token");
      setIsLogin(false);
      setSession(null);
      condition && window.open("/", "_self");
    } catch (error) {
      console.log(error);
    }
  };

  const contractMapping = {
    AVATAR: [CharacterNftAbi, characterGenerativeNFTContract],
    CAR: [BothgenerativeNFTABI, carGenerativeNFTContract],
    HOVERBOARD: [BothgenerativeNFTABI, hoverBoardGenerativeNFTContract],
  };
  const getCurrentMintingDetails = async (checkCategory) => {
    try {
      setMintDataLoad(true);
      const [abi, contract] = contractMapping[checkCategory] || [
        BothgenerativeNFTABI,
        bikeGenerativeNFTContract,
      ];
      const checkContractObj = getWeb3ContractObject(abi, contract);
      const maxTotalNftSupply = await checkContractObj.methods
        .MAX_NFT_SUPPLY()
        .call();
      setMaxNftSupply(Number(maxTotalNftSupply));
      const totalSupply = await checkContractObj.methods.totalSupply().call();
      setTotalSupply(Number(totalSupply));
      const perNftPriceSupply = await checkContractObj.methods
        .NFT_PRICE_ROUND1()
        .call();
      setPerNftPrice(Number(web3.utils.fromWei(perNftPriceSupply.toString())));
      setMintDataLoad(false);
    } catch (error) {
      console.log(error);
      setMintDataLoad(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("admin_token")) {
      adminProfileApi(localStorage.getItem("admin_token"));
    }
  }, [localStorage.getItem("admin_token")]);

  useEffect(() => {
    if (window.localStorage.getItem("ConnectWallet")) {
      walletConnect();
    }
  }, [localStorage.getItem("ConnectWallet")]);

  useEffect(() => {
    if (account && chainId) {
      getOurWalletBlc();
      connnectWalletApi(account);
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    if (selectCategory) {
      getCurrentMintingDetails(selectCategory);
    }
  }, [selectCategory]);

  let data = {
    userLoggedIn: isLogin,
    adminIsLogin: adminIsLogin,
    setIsLogin: (e) => setIsLogin(e),
    setAdminIsLogin,
    spinModalOpen,
    setSpinModalOpen,
    userData,
    adminUserData,
    account,
    library,
    chainId,
    perNftPrice,
    maxNftSupply,
    totalSupply,
    openMetamask,
    selectCategory,
    mintDataLoad,
    setSelectCategory,
    yourWalletBalance,
    getOurWalletBlc,
    metaMaskLoading,
    setOpenMetamask,
    isibiRequire,
    GetProfileUser: () => GetProfileUser(window.localStorage.getItem("token")),
    adminProfileApi: () =>
      adminProfileApi(window.localStorage.getItem("admin_token")),
    setisibiRequire,
    walletConnect: walletConnect,
    disconnectWalletHandler: disconnectWalletHandler,
    getCurrentMintingDetails: getCurrentMintingDetails,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
