import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar, { sections, settingAdmin, subAdmin } from "./NavBar";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import Footer from "./Footer";
import { AuthContext } from "src/context/Auth";
import { useHistory } from "react-router-dom";
import { getUpdatedRoutes, routeGroups } from "src/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#000",
    position: "relative",
  },
  root1: {
    backgroundColor: "#000",
    position: "relative",
    height: "100vh",
    "& .MainLayoutmain": {
      zIndex: "1",
      overflow: "hidden",
      position: "relative",
      backgroundSize: "cover",
      backgroundColor: "#F2F4F7",
      // backgroundImage: "url(/images/dahboard_banner.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
    },
  },
  wrapper1: {
    backgroundColor: "#000",
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    // paddingTop: 70,
    // minHeight: "calc(100vh - 75px)",
    minHeight: "100dvh",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    "@media (max-width:767px)": {
      paddingTop: "70px !important",
    },
  },
  wrapper: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#000",
    paddingTop: 70,

    minHeight: "100dvh",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    "@media (max-width:767px)": {
      paddingTop: "70px !important",
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    position: "relative",
    padding: "100px 40px 25px ",
    // minHeight: "600px",
    minHeight: "calc(100vh - 75px)",
    "@media (max-width: 1280px)": {
      padding: "100px 33px 20px ",
    },
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px 10px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 24px 10px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  // useEffect(() => {
  //   if (auth?.adminUserData) {
  //     const matchedItems = [...sections, ...subAdmin, ...settingAdmin].flatMap(
  //       (section) =>
  //         section.items.filter(
  //           (item) =>
  //             auth?.adminUserData?.permission &&
  //             [...auth?.adminUserData?.permission, ...["Dashboard"]].includes(
  //               item.title
  //             )
  //         )
  //     );
  //     const matchedArray = matchedItems?.map((item) => item.href);
  //     const result = getUpdatedRoutes(matchedArray, routeGroups);
  //     const matchFound = result.includes(window.location.pathname);
  //     if (
  //       auth.adminIsLogin &&
  //       auth?.adminUserData?.userType == "SUBADMIN" &&
  //       !matchFound
  //     ) {
  //       history.push("/dashboard");
  //     }
  //   }
  // }, [auth, window.location.pathname, sections, subAdmin]);

  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <Box className="MainLayoutmain">
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div
          className={
            themeSeeting.settings.theme === "DARK"
              ? `${classes.wrapper1}`
              : `${classes.wrapper}`
          }
        >
          <div className={classes.contentContainer}>
            <Box className="loginLeftSahdow"></Box>
            <Box className="loginRightSahdowdash"></Box>
            <div className={classes.content} id="main-scroll">
              {children}
            </div>
          </div>
          <Footer />
        </div>
      </Box>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
